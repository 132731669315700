import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import leaf from "../../Assets/Projects/h&m.png";
import beardo from "../../Assets/Projects/beardo.png";
import chatify from "../../Assets/Projects/nordstrom.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Koovs"
              description="A clone of Koovs.com website which offers online store for western fashion, catering to menswear, womenswear, footwear, accessories, jewellery and beauty products. "
              live="https://github.com/Ajit24/KOOVS_Clone"
              link="https://koovs.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Paula's Choice"
              description="we have built the E-Commerce Website using pure HTML ,CSS and Adv.JavaScript. We have created the clone of the PAULA'S CHOICE which is a e-commerce cosmetic website ."
              live="https://github.com/Ajit24/PAULA-S-CHOICE-WEB-PAGE"
              link="https://modest-visvesvaraya-03d3c2.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={beardo}
              isBlog={false}
              title="Youtube Clone"
              description="YouTube is an American online video sharing and social media platform owned by Google. It was launched on February 14, 2005, by Steve Chen, Chad Hurley, and Jawed Karim. It is the second most visited website, right after Google itself."
              live="https://github.com/Ajit24/YOUTUBE-mini"
              link="https://ecstatic-euler-675cf2.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
               imgPath={leaf}
              isBlog={false}
              title="Paula's Choice"
              description="we have built the E-Commerce Website using pure backend with ejs-express, mongodb-database, node-js, express-js. We have created the clone of the PAULA'S CHOICE which is a e-commerce cosmetic website ."
              live="https://github.com/Ajit24/paula_s_choice-backend"
              link="https://modest-visvesvaraya-03d3c2.netlify.app/"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
